
import TaxService from '../services/TaxService';
import RoleService from '../services/RoleService';
import LocalStorageService from '../services/LocalStorageService';

import { mapActions, mapGetters } from 'vuex';

import Pagination from '@/shared/Pagination.vue';
import { defineComponent } from 'vue';
import Tax from '@/Interfaces/Tax';
import Location from '@/Interfaces/Location';

interface ComponentData {
  textCount: number;
  searchTimeout: any;
  isPopupBlock: false;
  taxService: TaxService;
  roleService: RoleService;
  localStorageService: LocalStorageService;
  deleteTaxLocation: any;
  selectedTax: any;
  taxes: Tax[];
  deleteTaxId: string;
  loading: boolean;
  isSuperadminLoc: boolean;
  locs: Location[];
  locationOptions: Array<{ value: string | number | null; text: string }>;
  selectedLocationId: string;
  page: number;
  pageSize: number;
  lastPageResponse: number;
  noData: boolean;
  search: string;
  searchField: string;
  orderBy: string;
  orderByField: any[];
  ArrowAscName: boolean;
  ArrowAscTotal: boolean;
  valueName: string;
  taxValue: string;
  fieldSearchValue: string;
  sortingValue: string;
  searchFieldValue: string;

  totalCount: number;
  currentPage: number;
  nextPage: number;
  previusePage: number;
  records: number;
  isDisabled: boolean;
  checkDefaultTaxOnDelete: boolean;
  modalTitle: string;
  subMessage: string;
}

export default defineComponent({
  components: {
    Pagination,
  },
  data() {
    const data: ComponentData = {
      textCount: 0,
      searchTimeout: undefined,
      isPopupBlock: false,
      taxService: undefined,
      roleService: undefined,
      localStorageService: undefined,
      deleteTaxLocation: [],
      selectedTax: [],
      taxes: [],
      deleteTaxId: '',
      loading: true,
      isSuperadminLoc: false,
      locs: [],
      locationOptions: [{ value: null, text: 'Please select a location' }],
      selectedLocationId: undefined,
      page: 1,
      pageSize: 15,
      lastPageResponse: 0,
      noData: true,
      search: '',
      searchField: '',
      orderBy: 'ASC',
      orderByField: [],
      ArrowAscName: true,
      ArrowAscTotal: true,
      valueName: '',
      taxValue: '',
      fieldSearchValue: '',
      sortingValue: 'ASC',
      searchFieldValue: '',

      totalCount: 0,
      currentPage: 0,
      nextPage: 0,
      previusePage: 0,
      records: 0,
      isDisabled: true,
      checkDefaultTaxOnDelete: false,
      modalTitle: '',
      subMessage: '',
    };
    return data;
  },
  created() {
    this.taxService = new TaxService();
    this.roleService = new RoleService(new LocalStorageService());
    this.localStorageService = new LocalStorageService();
    this.selectedLocationId = this.localStorageService.getItem(
      'primary_location_id',
    );
  },
  async mounted() {
    this.loading = true;
    if (this.roleService.isStaff) {
      this.isDisabled = false;
    }
    await this.$store.dispatch('getLocationList');
    this.locs = this.$store.getters.allLocationList;
    this.setLocationOptions();
    if (this.selectedLocationId) {
      await this.changeLocation();
    }

    this.loading = false;
  },
  methods: {
    ...mapActions(['getTaxes', 'setTaxFilter']),
    goToPage({ page }) {
      this.getAllItems(page);
    },

    handleSorting(para1: any, para2: any) {
      if (para1 === 'name') {
        this.ArrowAscName = !this.ArrowAscName;
        this.sortingValue = para2;
      } else if (para1 === 'value') {
        this.ArrowAscTotal = !this.ArrowAscTotal;
        this.sortingValue = para2;
      }

      const filter = this.$store.getters.taxFilter;
      filter.page = this.page;
      filter.pageSize = this.pageSize;
      filter.orderBy = this.sortingValue;
      filter.orderByField = para1;
      this.$store.dispatch('setTaxFilter', filter).then(() => {
        this.$store.dispatch('getTaxes').then(() => {
          this.taxes = this.$store.getters.allTaxes;
          if (this.taxes.length) {
            this.noData = false;
          }
        });
      });
    },

    handleSearch_Field(event: any, orderByField: string, sortingValue: string) {
      this.textCount++;
      const temp = this.textCount;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        const filter = this.$store.getters.taxFilter;

        this.loading = true;
        this.searchFieldValue = event.target.value;
        filter.orderBy = sortingValue;
        filter.orderByField = orderByField;

        this.$store.dispatch('setTaxFilter', filter).then(() => {
          this.getAllItems(1, temp);
        });
      }, 1000);
    },

    changeLocation() {
      this.localStorageService.store(
        'primary_location_id',
        this.selectedLocationId,
      );
      this.loading = true;
      this.isSuperadminLoc = false;

      const locationOfSuperadmin =
        this.localStorageService.getItem('locations');
      if (
        locationOfSuperadmin &&
        locationOfSuperadmin.slice(2, -2) === this.selectedLocationId &&
        this.roleService.isSuperadmin
      ) {
        this.isSuperadminLoc = true;
      }
      const filter = this.$store.getters.taxFilter;
      filter.search = '';
      filter.locationId = this.selectedLocationId;
      this.$store.dispatch('setTaxFilter', filter).then(() => {
        this.getAllItems(1);
      });

      this.loading = false;
    },

    async getAllItems(page: any, searchNumber?: number) {
      this.noData = true;
      this.loading = true;
      const filter = this.$store.getters.taxFilter;
      filter.page = page;
      filter.pageSize = this.pageSize;
      filter.name =
        this.valueName && this.valueName.length > 0 ? this.valueName : '';
      filter.value =
        this.taxValue && this.taxValue.length > 0 && Number(this.taxValue)
          ? Number(this.taxValue) / 100
          : '';

      await this.$store
        .dispatch('setTaxFilter', filter)
        .then(async () => {
          await this.$store
            .dispatch('getTaxes')
            .then(() => {
              if (!searchNumber || this.textCount == searchNumber) {
                this.taxes = this.$store.getters.allTaxes;
                this.records = this.$store.getters.taxFilter.totalCount;
                this.totalCount = Math.ceil(this.records / this.pageSize);
                this.currentPage = page;
                this.loading = false;
                if (this.taxes.length) {
                  this.noData = false;
                }
              }
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.loading = false;
        });
    },

    formatTaxValue(taxValue: number) {
      let value = taxValue * 100;
      const split = value.toString().split('.');

      if (
        split.length &&
        split.length > 1 &&
        (split[1].length === 3 || Number(split[1]) > 700)
      ) {
        return value.toFixed(3) + '%';
      }
      return value.toFixed(2) + '%';
    },

    async deleteTax() {
      const tax = {
        id: this.deleteTaxId,
        name: '',
        value: 0,
        taxLocations: this.deleteTaxLocation,
      };
      await this.handleDelete(tax);
    },

    async handleDelete(tax: any) {
      this.loading = true;
      await this.taxService.removeTax(tax.id);
      this.taxes = this.taxes.filter((data) => data.id !== tax.id);
      this.loading = false;
    },

    showModalConfirm(tax: any) {
      if (tax.applyToAllGoods || tax.applyToAllServices) {
        this.checkDefaultTaxOnDelete = true;
        this.modalTitle = 'Delete Default Tax';
        this.subMessage =
          "Are you sure you want to delete this tax? It will no longer be applied to all goods/services and this action can't be undone.";
      } else {
        this.checkDefaultTaxOnDelete = false;
        this.modalTitle = 'Confirm Delete';
        this.subMessage = '';
      }
      this.deleteTaxId = tax.id;
      this.selectedTax = tax;
      this.deleteTaxLocation = tax.taxLocations;
      this.isPopupBlock = true;
    },

    deleteForAllLocation() {
      this.isPopupBlock = false;
      this.deleteTax();
    },

    async removeCurrentLocation() {
      this.isPopupBlock = false;
      this.loading = true;
      this.selectedTax.taxLocations[0].isActive = false;

      const selectedTax = {
        id: this.selectedTax.id,
        name: this.selectedTax.name,
        value: Math.round(this.selectedTax.value * 100 * 10) / 10,
        taxLocations: this.selectedTax.taxLocations,
      };
      await this.taxService.updateTax(selectedTax);
      this.taxes = this.taxes.filter((tax) => tax.id !== this.selectedTax.id);
      this.loading = false;
    },

    confirmationCancel() {
      this.isPopupBlock = false;
    },

    setLocationOptions() {
      this.locationOptions = this.locationOptions.concat(
        this.locs.map((location: any) => {
          return { value: location.id, text: location.name };
        }),
      );
      if (this.roleService.isStaffOrAdmin) {
        const locationOfUsers = this.localStorageService.getItem('locations');
        if (locationOfUsers != null) {
          const _locationOfUsers = JSON.parse(locationOfUsers);
          if (_locationOfUsers.length === 1) {
            this.selectedLocationId =
              _locationOfUsers[0].id ||
              this.localStorageService.getItem('primary_location_id');
            this.changeLocation();
          }
        }
      }
    },

    pageEvent({ page }) {
      this.page = page;
      this.getAllItems(page);
    },

    setPageSize(pageSize: number) {
      this.pageSize = pageSize;
      this.page = 1;
      this.getAllItems(1);
    },
  },
  computed: {
    ...mapGetters(['allTaxes', 'taxFilter']),
    getDeleteMessage() {
      const locationOfUsers = JSON.parse(
        this.localStorageService.getItem('locations'),
      );
      const currentLocation = locationOfUsers.filter(
        (data) => data.id === this.selectedLocationId,
      );
      return `Do you want to delete it for all locations or just ${currentLocation[0].name} ?`;
    },
  },
});
