
export default {
  name: 'Pagination',
  props: {
    pageSize: {
      default: 15,
    },
    totalCount: {
      default: 0,
    },
    currentPageParent: {},
    showPageSize: {
      default: true,
    },
  },
  data() {
    return {
      selectedRow: this.pageSize,
      rows: [
        { value: 15, text: '15' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
    };
  },
  emits: ['go-to-page', 'set-page-size'],
  computed: {
    lastPage() {
      return Math.ceil(this.totalCount / this.pageSize);
    },
    nextPage() {
      const nxtPage = this.currentPage + 1;
      return nxtPage <= this.lastPage ? nxtPage : this.lastPage;
    },
    previousPage() {
      const prevPage = this.currentPage - 1;
      return prevPage > 0 ? prevPage : 1;
    },
    currentPage() {
      return this.currentPageParent;
    },
    firstItem() {
      const firstEver = (this.currentPage - 1) * this.pageSize + 1;
      return this.currentPageParent === 1 ? 1 : firstEver;
    },
    toItem() {
      const to = this.selectedRow * this.currentPage;
      return to > this.totalCount ? this.totalCount : to;
    },
    pageSlots() {
      let pages = [];
      // Six slots in total
      if (this.lastPage < 6) {
        pages = [...[...Array(this.lastPage).keys()].map((n) => n + 1)];
      } else {
        pages = [1, 2, 3, 4, 5, 6];
        if (this.currentPage <= 4) {
          pages = [1, 2, 3, 4, -3, this.lastPage];
        }
        if (this.currentPage > 4) {
          pages = [
            1,
            -1,
            this.currentPage,
            this.currentPage + 1,
            -2,
            this.lastPage,
          ];
        }
        if (
          this.currentPage > this.lastPage - 4 &&
          this.currentPage <= this.lastPage
        ) {
          pages = [
            1,
            -1,
            this.lastPage - 3,
            this.lastPage - 2,
            this.lastPage - 1,
            this.lastPage,
          ];
        }
      }
      return pages;
    },
  },
  methods: {
    setToGoPage(slot: number) {
      if (slot === -3) {
        this.goToPage(null, 5);
        return;
      }
      if (slot === -2) {
        this.goToPage(null, this.currentPage + 2);
        return;
      }
      if (slot === -1) {
        this.goToPage(null, this.currentPage - 2);
        return;
      }
      if (slot > 0) {
        this.goToPage(null, slot);
      }
    },
    goToPage(index, page?) {
      this.$emit('go-to-page', { index, page });
    },
  },
};
